import React, { useEffect, useState } from "react"
import Helmet from "react-helmet"
import { useMediaPredicate } from "react-media-hook"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

import landingImg from "../../assets/img/corporatestructure/csi3.jpg"
import csPdf from "../../assets/pdf/SML-Corporate-Structure.pdf"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const CorporateStructurePage = ({ location }) => {

  const resizeScreen = useMediaPredicate("(max-width: 992px)")
  const ogUrl = process.env.BASE_URL + location.pathname + "/"
  
  useEffect(() => {
    let navbarDesktop = document.getElementById("desktopNav").querySelectorAll(".nav-link")
    let vLine = document.querySelector(".v-line")
    let langItem = document.querySelectorAll(".lang-item")
    let langItemActive = document.querySelector(".lang-item.active")
      
    if(resizeScreen){
      document.getElementsByClassName("logo-secondary")[1].style.display = `none`
      document.getElementsByClassName("logo-main")[1].style.display = `block`
    }else{
      document.getElementsByClassName("logo-secondary")[0].style.display = `none`
      document.getElementsByClassName("logo-main")[0].style.display = `block`
    }

    for (let i = 0; i < navbarDesktop.length; i++) {
      navbarDesktop[i].style.color = "#000"
    }

    for (let i = 0; i < langItem.length; i++) {
      langItem[i].style.color = "#000"
    }

    vLine.style.backgroundColor = "#000"
    langItemActive.style.color = "#AB0204"
  }, [])

  return(
    <Layout location={location} currentLocation={location.pathname} lang="EN">
      <SEO
        title={`Corporate Structure`}
        fbTitle={`Corporate Structure`}
        twitterTitle={`Corporate Structure`}
        url={ogUrl}
        fbDescription={`Simplified Corporate Structure as at 31 December 2022, with main subsidiaries, directly and indirectly held by Sinarmas Land Limited`}
        description={`Simplified Corporate Structure as at 31 December 2022, with main subsidiaries, directly and indirectly held by Sinarmas Land Limited`}
        twitterImg={landingImg}
        twitterDescription={`Simplified Corporate Structure as at 31 December 2022, with main subsidiaries, directly and indirectly held by Sinarmas Land Limited`}
        img={landingImg}
        fbImg={landingImg}
      />
      <Helmet>
        <body className="bd-page" />
        <meta name="keywords" content="corporate structure sinarmasland"></meta>
      </Helmet>
      <div className="col-md-12 csi-title">
        <h1>Corporate Structure</h1>
      </div>
      <div className="col-md-12 company-wrapper">
        <a 
          href="/sustainability#sus-report" 
          className="btn btn-company"
        >
          SINARMAS LAND LIMITED
        </a>
      </div>
      <div className="col-md-12 pdf-wrapper">
        <a 
          href={csPdf}
          target="_blank"
          className="btn btn-pdf"
        >
          Download PDF
        </a>
      </div>
      <div 
        className="py-main landing-img-wrapper"
        style={{
          background: `url(${landingImg}) no-repeat center`,
          backgroundSize: `contain`,
        }}
      >
        {/* <div className="col-md-12 csi-note">
          <p>
            Simplified Corporate Structure as at 31 December 2022, 
            with main subsidiaries, directly and indirectly held by 
            Sinarmas Land Limited
          </p>
          <ol type="1">
            <li>Listed on the SGX-ST</li>
            <li>Listed on the Indonesia Stock Exchange</li>
          </ol>
        </div> */}
      </div>
    </Layout>
  )
}
export default CorporateStructurePage